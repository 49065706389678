import React, { Fragment, useState, FC } from 'react';
import { currencyFormatter } from 'utils';
import ICONS from 'components/Icons';
import { SpaceSummary } from 'api/data/payout/types';
import './style.scss';

const Summary: FC<{ data: SpaceSummary }> = ({ data }) => {
  const [expandedGroups, setExpandedGroups] = useState<{ [key: string]: boolean }>({});

  const toggleGroup = (space: string) => {
    setExpandedGroups(prev => ({
      ...prev,
      [space]: !prev[space],
    }));
  };

  return (
    <>
      <p className="subtitle-medium">Summary</p>
      <table className="table list">
        <thead>
          <tr>
            <th className="subtitle-xx-small neutral">Spaces</th>
            <th className="row align-center subtitle-xx-small neutral justify-end">
              Amount {ICONS['arrow_slim_down']}
            </th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(data).map(([space, details], index) => (
            <Fragment key={index}>
              <tr>
                <td onClick={() => toggleGroup(space)} className="ellipsis">
                  <span className="paragraph-x-small ellipsis-text">
                    {details.transactions.length > 0 &&
                      (expandedGroups[space] ? ICONS['arrow_up'] : ICONS['arrow_right'])}{' '}
                    {space}
                  </span>
                </td>
                <td className="amount paragraph-x-small">{currencyFormatter(details.space_amount_cents)}</td>
              </tr>

              {expandedGroups[space] &&
                details.transactions.map(subItem => (
                  <tr key={subItem.transaction_id} className="sub-items">
                    <td className="ellipsis">
                      <span className="paragraph-x-small neutral ellipsis-text">{subItem.page_title}</span>
                    </td>
                    <td className="paragraph-x-small amount">{currencyFormatter(subItem.net_amount_cents)}</td>
                  </tr>
                ))}
            </Fragment>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default Summary;
