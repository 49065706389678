import React from 'react';
import { Switch, Route } from 'wouter';
import dayjs from 'dayjs';
import ReactGA from 'react-ga4';
import relativeTime from 'dayjs/plugin/relativeTime';
import { APP_CONFIG } from './api/config';
import UserRolePerspective from './contexts/UserRolePerspective';
import { isProduction } from 'utils';
import useCioPage from 'hooks/useCioPage';
import LoginRoute from 'components/LoginRoute';
import ProtectedRoute from 'components/ProtectedRoute';
import CheckoutPage from 'pages/PayerPage/Checkout';
import ClaimInvite from 'pages/ClaimInvite';
import EditorPage from 'pages/Editor';
import EnableTeam from './pages/EnableTeam';
import Error from 'pages/Error';
import InviteParticipant from 'pages/MyPage/Team';
import Login from 'pages/Login';
import LoginTokenRequest from 'pages/Login/LoginTokenRequest';
import MemberProfile from 'pages/MemberProfile';
import MyPage from 'pages/MyPage';
import MyPages from 'pages/MyPages';
import MyRequests from './pages/MyRequests';
import NewPage from 'pages/NewPage';
import NewPassword from 'pages/Login/PasswordRecover/NewPassword';
import NewSpace from 'pages/NewSpace';
import PagesRedirects from 'pages/PagesRedirects';
import Participant from './pages/Participant';
import PasswordRecover from 'pages/Login/PasswordRecover';
import PayerPage from 'pages/PayerPage';
import Payouts from 'pages/Payouts';
import PayoutsDetails from 'pages/Payouts/Details';
import RedirectToMyPage from 'pages/RedirectToMyPage';
import Request from 'pages/Request';
import SettingsPage from 'pages/Settings';
import SharePage from 'pages/Share';
import ShareParticipantPage from 'pages/Participant/ShareParticipantPage';
import SharePayerPage from './pages/PayerPage/SharePayerPage';
import Signup from './pages/Signup';
import SpaceSettings from './pages/SpaceSettings';
import SignupNewParticipant from './pages/Signup/Participant/New';
import SignupInvitedParticipant from './pages/Signup/Participant/Invited';
import SignupInviteSupporters from 'pages/Participant/InviteSupporters';
import SubmissionDetails from './pages/SubmissionDetails';
import SubmissionsReport from 'pages/Reports/Submissions';
import SubscriptionDetail from 'pages/MemberProfile/SubscriptionDetail';
import Template from 'pages/Template';
import TokenLogin from 'pages/Login/TokenLogin';
import Welcome from 'pages/Login/PasswordRecover/Welcome';

dayjs.extend(relativeTime);

ReactGA.initialize(APP_CONFIG.GOOGLE_ANALYTICS);

const redirectToPage = (path = '') => {
  isProduction() && window.location.assign(`https://www.omella.com/${path}`);
  return <div />;
};

function App(): JSX.Element {
  useCioPage();
  return (
    <>
      <section className="main">
        <Switch>
          <LoginRoute path="/" component={Login} />
          <LoginRoute path="/login" component={Login} />
          <Route path="/error/:code" component={Error} />
          <Route path="/tlogin" component={TokenLogin} />
          <Route path="/request-login-token" component={LoginTokenRequest} />
          <Route path="/invite/:inviteId" component={ClaimInvite} />
          <Route path="/password-recover" component={PasswordRecover} />
          <Route path="/new-password/:id/:token" component={NewPassword} />
          <Route path="/welcome/:id/:token" component={Welcome} />
          <Route path="/new" component={NewPage} />
          <Route path="/signup" component={Signup} />
          <Route path="/template/editor/:id" component={Template} />
          <Route path="/submissions/:id" component={SubmissionDetails} />

          <ProtectedRoute path="/member" viewAs="payer">
            {params => (
              <UserRolePerspective.Provider value="payer">
                <MemberProfile params={params} />
              </UserRolePerspective.Provider>
            )}
          </ProtectedRoute>
          <ProtectedRoute path="/member/subscription/:subscriptionId" viewAs="payer">
            {params => (
              <UserRolePerspective.Provider value="payer">
                <SubscriptionDetail params={params as { subscriptionId: string }} />
              </UserRolePerspective.Provider>
            )}
          </ProtectedRoute>
          <ProtectedRoute path="/member/:userId" component={MemberProfile} />
          <ProtectedRoute path="/member/:userId/subscription/:subscriptionId" component={SubscriptionDetail} />

          <ProtectedRoute path="/:currentSpace/my-profile" viewAs="payer">
            {params => (
              <UserRolePerspective.Provider value="payer">
                <MemberProfile params={params} />
              </UserRolePerspective.Provider>
            )}
          </ProtectedRoute>
          <ProtectedRoute path="/:currentSpace/my-profile/subscription/:subscriptionId" viewAs="payer">
            {params => (
              <UserRolePerspective.Provider value="payer">
                <SubscriptionDetail params={params as { subscriptionId: string }} />
              </UserRolePerspective.Provider>
            )}
          </ProtectedRoute>
          <ProtectedRoute path="/:currentSpace/member/:userId" component={MemberProfile} />
          <ProtectedRoute
            path="/:currentSpace/member/:userId/subscription/:subscriptionId"
            component={SubscriptionDetail}
          />
          <ProtectedRoute path="/:currentSpace/new-space" component={NewSpace} />
          <ProtectedRoute path="/:currentSpace/pages" component={MyPages} />
          <ProtectedRoute path="/:currentSpace/:location/:id/share" component={SharePage} />
          <ProtectedRoute path="/:currentSpace/:location/:id/:tab/share" component={SharePage} />
          <ProtectedRoute path="/:currentSpace/:location/:id/:tab/:subTab/share" component={SharePage} />
          <ProtectedRoute path="/:currentSpace/pages/:id" component={MyPage} />
          <ProtectedRoute path="/:currentSpace/pages/:id/:tab" component={MyPage} />
          <ProtectedRoute path="/:currentSpace/pages/:id/team/invite" component={InviteParticipant} />
          <ProtectedRoute path="/:currentSpace/pages/:id/team/enable" component={EnableTeam} />
          <ProtectedRoute path="/:currentSpace/pages/:id/team/:participantSlug" component={Participant} />
          <ProtectedRoute path="/:currentSpace/pages/:id/:tab/:subTab" component={MyPage} />
          <ProtectedRoute path="/:currentSpace/submissions/:id" component={SubmissionDetails} />
          <ProtectedRoute path="/:currentSpace/settings" component={SettingsPage} action="ORGANIZATION_SETTINGS" />
          <ProtectedRoute path="/:currentSpace/payouts" component={Payouts} action="PAYOUTS" />
          <ProtectedRoute path="/:currentSpace/payouts/:id" component={PayoutsDetails} action="PAYOUTS" />
          <ProtectedRoute path="/:currentSpace/settings/:tab" component={SettingsPage} action="ORGANIZATION_SETTINGS" />
          <ProtectedRoute path="/:currentSpace/space-settings" component={SpaceSettings} action="SPACE_SETTINGS" />
          <ProtectedRoute path="/:currentSpace/space-settings/:tab" component={SpaceSettings} action="SPACE_SETTINGS" />
          <ProtectedRoute path="/:currentSpace/editor/:id/requests/:requestId" component={Request} />
          <ProtectedRoute path="/:currentSpace/requests" component={MyRequests} />
          <ProtectedRoute path="/:currentSpace/reports" component={SubmissionsReport} />
          <ProtectedRoute path="/:currentSpace/error/:code" component={Error} />
          <ProtectedRoute path="/:id/p/:participantSlug/edit" component={Participant} viewAs="participant" />
          <ProtectedRoute path="/pages" component={PagesRedirects} />

          <Route path="/terms-of-service">{() => redirectToPage('terms-of-service')}</Route>
          <Route path="/request-invite">{() => redirectToPage('request-invite')}</Route>
          <Route path="/contact-us">{() => redirectToPage('contact-us')}</Route>
          <Route path="/privacy-policy">{() => redirectToPage('privacy-policy')}</Route>
          <Route path="/:id" component={PayerPage} />
          <Route path="/:id/edit" component={RedirectToMyPage} />
          <Route path="/0/:id" component={PayerPage} />
          <Route path="/o/:id" component={PayerPage} />
          <Route path="/:id/p/signup" component={SignupNewParticipant} />
          <Route path="/:id/share" component={SharePayerPage} />
          <Route path="/:id/share-participant" component={ShareParticipantPage} />
          <Route path="/:id/p/:participantSlug" component={PayerPage} />
          <Route path="/:id/p/:participantSlug/share-participant" component={ShareParticipantPage} />
          <Route path="/:id/p/:participantSlug/signup" component={SignupInvitedParticipant} />
          <Route path="/:id/p/:participantSlug/signup/invite-supporters" component={SignupInviteSupporters} />
          <Route path="/requests/:requestId" component={PayerPage} />
          <Route path="/:id/requests/:requestId" component={PayerPage} />
          <Route path="/:id/checkout" component={CheckoutPage} />
          <Route path="/:id/checkout/:responseId" component={CheckoutPage} />
          <Route path="/editor/new-page" component={EditorPage} />
          <Route path="/:rest*">{params => `404, Sorry the page ${params.rest} does not exist!`}</Route>
        </Switch>
      </section>
    </>
  );
}

export default App;
