import React, { FC, ReactNode, useState } from 'react';
import clsx from 'clsx';
import { isEmpty } from 'components/RichTextEditor/utils';
import { defaultPageTitle } from 'defaults/page';
import { BlockState, BlockTextState } from 'api/data/pages/blocks/types';
import { PageDataDraftBlock } from 'api/data/pages/types';
import './style.scss';

interface ContainerBlockProps {
  blockItem: BlockState;
  page: PageDataDraftBlock;
  children: ReactNode;
}

const ContainerBlock: FC<ContainerBlockProps> = ({ blockItem, page, children }: ContainerBlockProps) => {
  const [showIcons, setShowIcons] = useState(false);
  const blockIsEmpty = isEmpty((page.blocks[0] as BlockTextState)?.textBlock?.data);

  const hideIconsMenu = () => {
    if (showIcons) setShowIcons(false);
  };

  const showIconsMenu = () => {
    if (!showIcons) setShowIcons(true);
  };

  return (
    <div
      onMouseMove={showIconsMenu}
      onMouseLeave={hideIconsMenu}
      onKeyPress={hideIconsMenu}
      role="none"
      className={clsx(`container ${blockItem.type.toLowerCase()}`, {
        'highlight-placeholder': page.title !== defaultPageTitle && page.blocks.length <= 1 && blockIsEmpty,
        'show-menu-icons': showIcons,
      })}>
      {children}
    </div>
  );
};

export default ContainerBlock;
