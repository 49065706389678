import React, { FC, useState } from 'react';
import { useQuery } from '@apollo/client';
import clsx from 'clsx';
import { isKYCd } from 'utils';
import * as ResponseQuery from 'graphql/response.graphql';
import * as SubscriptionQuery from 'graphql/subscription.graphql';
import * as TransactionQuery from 'graphql/transaction.graphql';
import useCurrentOrganization from 'hooks/useCurrentOrganization';
import Details from '../Details';
import Tabs from 'components/Tabs';
import Tab from 'components/Tabs/Tab';
import { Transactions } from 'components/ResponseDetail/Transactions';
import { Subscriptions } from 'components/ResponseDetail/Subscriptions';
import { ResponseInfo, PageResponses as PageResponsesType } from 'api/data/response/types';
import { Subscriptions as SubscriptionsType } from 'api/data/subscription/types';
import { Transactions as TransactionsType } from 'api/data/transaction/types';
import { SubmissionType } from '../Details/SuccessCard';
import './style.scss';

export type SubmissionsDetailsTabsProps = {
  responseId: string;
  submissionType?: SubmissionType;
  participantSlug?: string;
  autoTrackActivities?: boolean;
  refetchSubmissionsResponse?: () => void;
};

const SubmissionDetailsTabs: FC<SubmissionsDetailsTabsProps> = ({
  responseId,
  submissionType,
  participantSlug,
  refetchSubmissionsResponse,
  autoTrackActivities,
}) => {
  const { currentOrg } = useCurrentOrganization();

  const [tabContentKey, setTabContentKey] = useState('details');
  const {
    data: responseData,
    loading: responseLoading,
    refetch: refetchResponse,
  } = useQuery<ResponseInfo>(ResponseQuery.GetResponseForReceipt, {
    variables: { id: responseId },
  });

  const { data: transactionsData } = useQuery<TransactionsType>(TransactionQuery.GetTransactions, {
    variables: { id: responseId, currentSpace: responseData?.response?.page?.space?.slug },
    skip: !currentOrg || !responseData?.response,
  });

  const { data: subscriptionsData } = useQuery<SubscriptionsType>(SubscriptionQuery.GetSubscriptions, {
    variables: { id: responseId, currentSpace: responseData?.response?.page?.space?.slug },
    skip: !currentOrg || !responseData?.response,
  });

  const { data: pageResponsesData } = useQuery<PageResponsesType>(ResponseQuery.PageResponses, {
    variables: { pageId: responseData?.response?.pageId, currentSpace: responseData?.response?.page?.space?.slug },
    skip: !currentOrg || !responseData?.response,
    fetchPolicy: 'cache-and-network',
  });

  if (responseLoading) return <div>Loading...</div>;

  const pageResponse = pageResponsesData?.pageResponses.responses.filter(response => response.id === responseId)[0];

  const offlineTransaction = transactionsData?.transactions.find(
    transaction => transaction.paymentMethodType === 'offline',
  );

  const tabContent: {
    [key: string]: JSX.Element;
  } = {
    details: (
      <Details
        page={responseData?.response?.page}
        paramId={responseId}
        autoTrackActivities={autoTrackActivities}
        response={responseData?.response}
        offlineTransaction={offlineTransaction}
        items={responseData?.response.answersAndItems}
        submissionType={submissionType}
        participantSlug={participantSlug}
        refetchSubmissionsResponse={refetchSubmissionsResponse}
        refetchPage={() => void refetchResponse()}
      />
    ),
    payments: (
      <Transactions
        pageResponse={pageResponse}
        transactions={transactionsData?.transactions || []}
        pageId={responseData?.response?.pageId}
        pageTitle={responseData?.response?.page?.title || ''}
      />
    ),
    subscriptions: (
      <Subscriptions
        responseId={responseId}
        subscriptions={subscriptionsData?.subscriptions || []}
        pageId={responseData?.response?.pageId}
        pageSpaceSlug={responseData?.response?.page?.space?.slug}
      />
    ),
  };

  return (
    <div className="submission-details-tabs">
      {currentOrg?.status && (
        <div
          className={clsx('fixed-header', {
            'banner-space': !!currentOrg?.status && !isKYCd(currentOrg?.status),
          })}>
          <Tabs activeKey={tabContentKey}>
            <Tab label="Details" tabKey="details" onClick={setTabContentKey} />
            <Tab label="Payments" tabKey="payments" onClick={setTabContentKey} />
            <Tab label="Subscriptions" tabKey="subscriptions" onClick={setTabContentKey} />
          </Tabs>
        </div>
      )}
      <div>{tabContent[tabContentKey]}</div>
    </div>
  );
};

export default SubmissionDetailsTabs;
